import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";

import HomePage from "../views/HomePage.vue";
import { cookieMan } from '@/_helpers';
import { session } from '@/_services';

const ProductDetail = () => import(/* webpackPrefetch: true */ "../views/ProductDetail.vue");
const ProductDetailReview = () => import(/* webpackPrefetch: true */ "../views/ProductDetailReview.vue");
const ProductList = () => import(/* webpackPrefetch: true */ "../views/ProductList.vue");
const ShopByBrand = () => import(/* webpackPrefetch: true */ "../views/ShopByBrand");
const DetailProfile = () => import(/* webpackPrefetch: true */ "../views/profiles/DetailProfile.vue");
const EditProfile = () => import(/* webpackPrefetch: true */ "../views/profiles/EditProfile.vue");
const ChangePasswordProfile = () => import(/* webpackPrefetch: true */ "../views/profiles/ChangePasswordProfile.vue");
const RecentOrderProfile = () => import(/* webpackPrefetch: true */ "../views/profiles/RecentOrderProfile.vue");
const MyAddress = () => import(/* webpackPrefetch: true */ "../views/profiles/MyAddressProfile.vue");
const MyLizPoint = () => import(/* webpackPrefetch: true */ "../views/profiles/MyLizPointsProfile.vue");
// const BPTask = () => import(/* webpackPrefetch: true */ '../views/reseller/BPTask');
// const BPTaskDetail = () => import(/* webpackPrefetch: true */ '../views/reseller/BPTaskDetail');
// const ManageCatalog = () => import(/* webpackPrefetch: true */ '../views/reseller/ManageCatalog');
// const ResellerLevel = () => import(/* webpackPrefetch: true */ "../views/profiles/ResellerLevel");
// const ResellerMembership = () => import(/* webpackPrefetch: true */ "../views/profiles/ResellerMembership.vue");
// const ResellerReferral = () => import(/* webpackPrefetch: true */ "../views/profiles/ResellerReferral.vue");
// const ResellerCommission = () => import(/* webpackPrefetch: true */ "../views/profiles/ResellerCommission.vue");
// const ResellerSalesOrder = () => import(/* webpackPrefetch: true */ "../views/profiles/ResellerSalesOrder.vue");
const ProductReview = () => import(/* webpackPrefetch: true */ "../views/profiles/ProductReviewProfile.vue");
const LizMagz = () => import(/* webpackPrefetch: true */ "../views/lizmagz/LizMagazinePage.vue");
const DetailLizMagz = () => import(/* webpackPrefetch: true */ '../views/lizmagz/MagazinePage.vue');
const Checkout = () => import(/* webpackPrefetch: true */ '../views/CheckoutPage.vue');
const ThankYou = () => import(/* webpackPrefetch: true */ '../views/ThankYou.vue');
// const CheckoutRedeem = () => import(/* webpackPrefetch: true */ '../views/CheckoutRedeem.vue');
const OrderDetail = () => import(/* webpackPrefetch: true */ '../views/profiles/recentOrders/OrderDetail.vue');
const LizPedia = () => import(/* webpackPrefetch: true */ '../views/StaticPage.vue');
const StaticPage = () => import(/* webpackPrefetch: true */ '../views/StaticPage.vue');
const CollectionsPage = () => import(/* webpackPrefetch: true */ '../views/CollectionsPage.vue');
const PromoPage = () => import(/* webpackPrefetch: true */ '../views/PromoPage.vue');
const Voice = () => import(/* webpackPrefetch: true */ '../views/campaign/Voice.vue');
// const TrueColorVoice = () => import(/* webpackPrefetch: true */ '../views/campaign/TrueColorVoice.vue');
// const TrueColor = () => import(/* webpackPrefetch: true */ '../views/campaign/TrueColor.vue');
const RegisterMerchant = () => import(/* webpackPrefetch: true */ '../views/RegisterMerchant.vue');
// const RegisterReferral = () => import(/* webpackPrefetch: true */ '../views/RegisterReferral.vue');
const Testimony = () => import(/* webpackPrefetch: true */ '../views/Testimony.vue');
const FAQ = () => import(/* webpackPrefetch: true */ '../views/FAQ.vue');
// const CollaborationPage = () => import(/* webpackPrefetch: true */ '../views/CollaborationPage.vue');
const ResetPassword = () => import(/* webpackPrefetch: true */ '../views/ResetPassword.vue');
const WebviewContent = () => import(/* webpackPrefetch: true */ '../views/WebviewContent');
const GroupCatalogForCustomer = () => import (/* webpackPrefetch: true */ '../views/catalog/GroupCatalogForCustomer');
const ProductCatalogForCustomer = () => import (/* webpackPrefetch: true */ '../views/catalog/ProductCatalogForCustomer');
// const FindOurBP = () => import(/* webpackPrefetch: true */ '../views/bpCustomer/FindOurBP');
// const BPList = () => import(/* webpackPrefetch: true */ '../views/bpCustomer/BPList');
// const BPRegistration = () => import(/* webpackPrefetch: true */ '../views/bpCustomer/BPRegistration');
// const Lookbook = () => import(/* webpackPrefetch: true */ '../views/Lookbook.vue');

Vue.use(VueRouter);
Vue.use(Meta)

const routes = [{
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/login",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/activate/:id",
    name: "HomePage",
    component: HomePage,
  },
  // {
  //   path: "/activate-bus-partner/:id",
  //   name: "HomePage",
  //   component: HomePage,
  // },
  {
    path: "/account-delete/:code",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/webview/:param/:id",
    name: "Webview",
    component: WebviewContent,
  },
  // {
  //   path: "/collaboration",
  //   component: CollaborationPage
  // },
  {
    path: "/static/:page",
    name: "StaticPage",
    component: StaticPage,
  },
  {
    path: "/lizpedia/:page",
    name: "StaticPage",
    component: LizPedia,
  },
  {
    path: "/find-your-style",
    name: "FindYourStyle",
    component: CollectionsPage,
  },
  {
    path: "/promo",
    name: "PromoPage",
    component: PromoPage,
  },
  {
    path: "/lizmagz/:slug",
    name: "DetailLizMagz",
    component: DetailLizMagz,
  },
  {
    path: "/lizmagz",
    name: "LizMagz",
    component: LizMagz,
  },
  {
    path: "/product/:id",
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/product/:slug/review",
    name: "ProductDetailReview",
    component: ProductDetailReview,
  },
  {
    path: "/product-catalog/:slug",
    name: "GroupCatalogForCustomer",
    component: GroupCatalogForCustomer,
  },
  {
    path: "/product-catalog/:slug/:slug2",
    name: "ProductCatalogForCustomer",
    component: ProductCatalogForCustomer,
  },
  {
    path: "/product-catalog/:slug/:slug2/:productId",
    name: "ProductCatalogForCustomer",
    component: ProductCatalogForCustomer,
  },
  // Start product list routes
  {
    path: "/products/search/:keyWord",
    name: "ProductList",
    component: ProductList,
  },
  {
    path: "/products/:slug/:slug2/:slug3",
    name: "ProductList",
    component: ProductList,
  },
  {
    path: "/products/:slug/:slug2",
    name: "ProductList",
    component: ProductList,
  },
  {
    path: "/products/:slug",
    name: "ProductList",
    component: ProductList,
  },
  {
    path: "/shop-by-brands/:brand",
    name: "ProductListByBrand",
    component: ProductList,
  },
  {
    path: "/shop-by-brands",
    name: "ShopByBrand",
    component: ShopByBrand,
  },
  // End product list routes
  {
    path: "/profile",
    name: "DetailProfile",
    component: DetailProfile,
    beforeEnter: (to, from, next) => {
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },
  // {
  //   path: "/upgrade-bus-partner",
  //   name: "DetailProfile",
  //   component: DetailProfile,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  // {
  //   path: "/checkout-redeem",
  //   name: "CheckoutRedeem",
  //   component: CheckoutRedeem,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },
  {
    path: "/order-completed/:code",
    name: "OrderCompleted",
    component: ThankYou,
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: EditProfile,
    beforeEnter: (to, from, next) => {
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },
  {
    path: "/change-password",
    name: "ChangePasswordProfile",
    component: ChangePasswordProfile,
    beforeEnter: (to, from, next) => {
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },
  {
    path: "/recent-order",
    name: "RecentOrderProfile",
    component: RecentOrderProfile,
    beforeEnter: (to, from, next) => {
      const orderId = to.query.order_id || cookieMan.get('redir_order_number')
      if (orderId) return next(`/order-completed/${orderId}`)
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },
  {
    path: "/my-address",
    name: "MyAddress",
    component: MyAddress,
    beforeEnter: (to, from, next) => {
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },

  {
    path: "/my-liz-point",
    name: "MyLizPoint",
    component: MyLizPoint,
    beforeEnter: (to, from, next) => {
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },
  // {
  //   path: "/bus-partner-level",
  //   name: "ResellerLevel",
  //   component: ResellerLevel,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  // {
  //   path: "/bus-partner-membership",
  //   name: "ResellerMembership",
  //   component: ResellerMembership,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  // {
  //   path: "/bus-partner-referral",
  //   name: "ResellerReferral",
  //   component: ResellerReferral,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  // {
  //   path: "/bus-partner-commission",
  //   name: "ResellerCommission",
  //   component: ResellerCommission,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  // {
  //   path: "/bus-partner-sales-order",
  //   name: "ResellerSalesOrder",
  //   component: ResellerSalesOrder,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  // {
  //   path: "/bus-partner-task",
  //   name: "BPTask",
  //   component: BPTask,
  //   beforeEnter: async (to, from, next) => {
  //     if (await session()) next()
  //     else next('/login?next=' + encodeURIComponent(to.fullPath))
  //   }
  // },
  // {
  //   path: "/bus-partner-task/:id",
  //   name: "BPTaskDetail",
  //   component: BPTaskDetail,
  //   beforeEnter: async (to, from, next) => {
  //     if (await session()) next()
  //     else next('/login?next=' + encodeURIComponent(to.fullPath))
  //   }
  // },
  // {
  //   path: "/manage-catalog",
  //   name: "ManageCatalog",
  //   component: ManageCatalog,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next()
  //       else next('/login?next=' + encodeURIComponent(to.fullPath))
  //     })
  //   }
  // },
  {
    path: "/product-review",
    name: "ProductReview",
    component: ProductReview,
    beforeEnter: (to, from, next) => {
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },
  {
    path: "/order-detail/:id",
    name: "OrderDetail",
    component: OrderDetail,
    beforeEnter: (to, from, next) => {
      session().then(resolve => {
        if(resolve) next()
        else next('/login?next=' + encodeURIComponent(to.fullPath))
      })
    }
  },
  // {
  //   path: "/campaign/voice",
  //   name: "Voice",
  //   component: Voice,
  // },
  {
    path: "/campaign/voice/:slug",
    name: "Voice",
    component: Voice,
  },
  // {
  //   path: "/campaign/true-color-voice",
  //   name: "TrueColorVoice",
  //   component: TrueColorVoice,
  // },
  // {
  //   path: "/campaign/true-color",
  //   name: "TrueColor",
  //   component: TrueColor,
  // },
  {
    path: "/register-merchant",
    name: "RegisterMerchant",
    component: RegisterMerchant,
  },
  // {
  //   path: "/register/:slug",
  //   name: "RegisterReferral",
  //   component: RegisterReferral,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next('/')
  //       else next()
  //     })
  //   }
  // },
  // {
  //   path: "/register-bus-partner",
  //   name: "RegisterResellerReferral",
  //   component: HomePage,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next('/')
  //       else next()
  //     })
  //   }
  // },
  // {
  //   path: "/register-bus-partner/:slug",
  //   name: "RegisterResellerReferral",
  //   component: HomePage,
  //   beforeEnter: (to, from, next) => {
  //     session().then(resolve => {
  //       if(resolve) next('/')
  //       else next()
  //     })
  //   }
  // },
  {
    path: "/reset-password/:key",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/our-review",
    name: "Testimony",
    component: Testimony
  },
  {
    path: "/faqs",
    name: "FAQ",
    component: FAQ,
  },
  // {
  //   path: "/find-our-bp",
  //   name: "FindOurBP",
  //   component: FindOurBP,
  // },
  // {
  //   path: "/find-our-bp/bp-list",
  //   name: "BPList",
  //   component: BPList,
  // },
  // {
  //   path: "/find-our-bp/registration",
  //   name: "BPRegistration",
  //   component: BPRegistration,
  // },
  // {
  //   path: "/lookbook/:page",
  //   name: "Lookbook",
  //   component: Lookbook,
  // },
  {
    path: "*",
    redirect: '/',
    component: HomePage,
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

export default router;