import Vue from 'vue'
import Vuex from 'vuex'
import { cookieMan } from '@/_helpers';

Vue.use(Vuex)

const initUserState = () => ({
  user_token: localStorage.getItem('token'),
  userData: null,
  listPayment: null,
  paymentMethod: null,
  agreed: false,
  agreedLM: false,
  useLizpoint: false,
  checkoutData: null,
  shoppingBag: [],
  wishlist: [],
  orderDetail: null,
  showHotIcon: true,
  brandHistory: ((cookie) => {
    try {
      return JSON.parse(cookie)
    } catch {
      return []
    }
  })(cookieMan.get("fe_history_brand")),
  productAddToCartAfterLogin: null,

  // Start reseller states
  resellerData: null,
  resellerRegistration: null,
  membershipPayment: null,

  // End reseller states
  isLoggedIn: localStorage.getItem('token') ? true : false,

  // Quick checkout states
  tempSession: cookieMan.get('fe_guest_session'),
  tempToken: cookieMan.get('fe_guest_token'),

  // Quickblox states
  qbHasSession: false,
  dialogList : {},
  currentDialog: {}, 
  messageList : {},
  messagePreview: "",
  currentDialogOpTime: null,
  currentDialogMerchantData: null,

  //* Migration juene customer
  isJuene: false,

  //* Whizliz UI Theme (Available: DEFAULT, RAMADHAN)
  whizlizTheme: new Date() <= new Date(2023, 11, 31, 23, 59, 59)? "CHRISTMAS" : "DEFAULT",
})

const isLoading = () => {
  return {
    dialogList: true,
    messageList: true,
  }
}

const systemMessageSendStatus = () => ({
  merchantOperationalTime: false
})

export default new Vuex.Store({
  state: Object.assign({
    // Start of filter states,
    brands: [],
    groupedBrands: [],
    collections: [],
    promos: [],
    categories: [],
    topmenu: [],
    isLoading: isLoading(),
    systemMessageSendStatus: systemMessageSendStatus(),
  }, initUserState()),
  mutations: {
    // Default user state
    resetUserState(state) {
      Object.assign(state, initUserState())
    },
    // User token
    setUserToken(state, payload) {
      state.user_token = payload  
    },
    // User state
    setUserData(state, payload) {
      state.userData = payload
    },
    // Checkout states
    setListPayment(state, payload) {
      state.listPayment = payload
    },
    setPaymentMethod(state, payload) {
      state.paymentMethod = payload
    },
    setAgree(state) {
      state.agreed = !state.agreed
    },
    setAgreeLM(state) {
      state.agreedLM = !state.agreedLM
    },
    setUseLizpoint(state) {
      state.useLizpoint = !state.useLizpoint
    },
    setCheckoutData(state, payload) {
      state.checkoutData = payload
    },
    // User UI states
    setShoppingBag(state, payload) {
      state.shoppingBag = payload
    },
    setWishlist(state, payload) {
      state.wishlist = payload
    },
    setOrderDetail(state, payload) {
      state.orderDetail = payload
    },
    // Product list filter states
    setBrands(state, payload) {
      state.brands = payload
    },
    setCollections(state, payload) {
      state.collections = payload
    },
    setPromos(state, payload) {
      state.promos = payload
    },
    setCategories(state, payload) {
      state.categories = payload
    },
    // QB states
    setQbSession(state, payload) {
      state.qbHasSession = payload
    },
    setDialogList (state, payload) {
      state.dialogList = payload
    },
    setCurrentDialog (state, payload) {
      state.currentDialog = payload
    },
    setDialogParams (state, payload) {
      state.dialogList = Object.assign(payload, state.dialogList);
    },
    pushNewDialog (state, dialog) {
      state.dialogList = Object.assign({[dialog._id]: dialog}, state.dialogList);
    },
    decreaseUnreadCounter (state, dialogId) {
      state.dialogList[dialogId].unread_messages_count = 0
    },
    setMessageList (state, payload) {
      state.messageList = payload
    },
    pushNewMessage (state, message) {
      state.messageList[message.dateIds].push(message)
    },
    setMessageStatus (state, message) {
      state.messageList[message.dateIds].find(el => 
        el._id === message._id
      ).status = "read"; 
    },
    setMessagePreview (state, payload) {
      state.messagePreview = payload
    },
    setCurrentDialogOpTime (state, payload) {
      state.currentDialogOpTime = payload
    },
    setCurrentDialogMerchantData (state, payload) {
      state.currentDialogMerchantData = payload
    },
    setSystemMessageSendStatus (state, {key, value}) {
      state.systemMessageSendStatus[key] = value
    },
    // Other states
    setShowHotIcon(state, payload) {
      state.showHotIcon = payload
    },
    setBrandHistory(state, payload) {
      state.brandHistory = payload
    },
    setProductAddToCartAfterLogin(state, payload) {
      state.productAddToCartAfterLogin = payload
    },
    // Start set reseller states
    setResellerData(state, payload) {
      state.resellerData = payload
    },
    setResellerRegistration(state, payload) {
      state.resellerRegistration = payload
    },
    setMembershipPayment(state, data) {
      state.membershipPayment = data;
    },
    // End set reseller states
    setIsLoggedIn(state, data) {
      state.isLoggedIn = data
    },
    // Additional states
    setTopmenu(state, data) {
      state.topmenu = data
    },
    setGroupedBrands(state, payload) {
      state.groupedBrands = payload
    },

    // Set quick checkout states
    setGuestSession(state, payload) {
      state.tempSession = payload
    },
    setGuestToken(state, payload) {
      state.tempToken = payload
    },
    
    // Set isLoading states
    setIsLoading (state, {key, value}) {
      state.isLoading[key] = value
    },
    
    //* Set isJuene state
    setIsJuene (state, payload) {
      state.isJuene = payload
    },
  },
  actions: {
    resetUserState({ commit }) {
      // search local storage
      const savedStorage = ((...keys) => {
        const obj = {}
        for (const key of keys) {
          try {
            obj[key] = JSON.parse(localStorage.getItem(key)) 
          } catch {
            obj[key] = localStorage.getItem(key)
          }
        }
        return obj
      })('checkoutData')

      // clear local storage
      localStorage.clear()

      // re-set local saved storage
      for (const key of Object.keys(savedStorage))
        localStorage.setItem(key, typeof savedStorage[key] == 'object' ? JSON.stringify(savedStorage[key]) : savedStorage[key])

      commit("resetUserState")
    },
    setUserToken({ commit }, payload) {
      commit("setUserToken", payload)
    },
    setUserData({ commit }, payload) {
      commit("setUserData", payload)
      if (!this.state.isLoggedIn) localStorage.removeItem('checkoutData')
      if (payload) commit("setIsLoggedIn", true)

      // Clear temp session & guest token
      cookieMan.remove('fe_guest_session')
      cookieMan.remove('fe_guest_token')
      commit("setGuestSession", null)
      commit("setGuestToken", null)
    },
    setListPayment({ commit }, payload) {
      commit("setListPayment", payload)
    },
    setPaymentMethod({ commit }, payload) {
      commit("setPaymentMethod", payload)
    },
    setAgree({ commit }) {
      commit("setAgree")
    },
    setAgreeLM({ commit }) {
      commit("setAgreeLM")
    },
    setUseLizpoint({ commit }) {
      commit("setUseLizpoint")
    },
    setCheckoutData({ commit }, payload) {
      commit("setCheckoutData", payload)
    },
    setShoppingBag({ commit }, payload) {
      commit("setShoppingBag", payload)
    },
    setWishlist({ commit }, payload) {
      commit("setWishlist", payload)
    },
    setOrderDetail({ commit }, payload) {
      commit("setOrderDetail", payload)
    },
    setBrands({ commit }, payload) {
      commit("setBrands", payload)
    },
    setCollections({ commit }, payload) {
      commit("setCollections", payload)
    },
    setPromos({ commit }, payload) {
      commit("setPromos", payload)
    },
    setCategories({ commit }, payload) {
      commit("setCategories", payload)
    },
    // Action QB states
    setQbSession({ commit }, payload) {
      commit("setQbSession", payload)
    },
    setDialogList ({commit}, data) {
      commit("setDialogList", data)
    },
    setCurrentDialog ({commit}, data) {
      commit("setCurrentDialog", data)
    },
    setDialogParams({commit,state}, message) {
      let tmpObj = {};
      tmpObj[message.chat_dialog_id] = state.dialogList[message.chat_dialog_id];
      tmpObj[message.chat_dialog_id].last_message = message.message;
      tmpObj[message.chat_dialog_id].last_message_date_sent = message.date_sent;
      tmpObj[message.chat_dialog_id].last_message_id = message._id;
      tmpObj[message.chat_dialog_id].last_message_user_id = message.sender_id;
      if (!message.selfReaded) {
        tmpObj[message.chat_dialog_id].unread_messages_count++;
      }
      delete state.dialogList[message.dialog_id];
      commit("setDialogParams", tmpObj)
    },
    pushNewDialog({commit}, dialog) {
      commit("pushNewDialog", dialog)
    },
    decreaseUnreadCounter({commit}, dialogId) {
      commit("decreaseUnreadCounter", dialogId)
    },
    setMessageList ({commit}, data) {
      commit("setMessageList", data)
    },
    pushNewMessage ({commit,state}, messages) {
      for (var key in messages) {
        // For new date property "DD/MM/YYYY" if doesn't exist
        if(state.messageList[key] === undefined) {
          state.messageList = { ...state.messageList, [key]: new Array() }
        }
      }
      commit("pushNewMessage", messages[key][0])
    },
    setMessageStatus ({commit}, message) {
      commit("setMessageStatus", message)
    },
    setMessagePreview ({commit}, payload) {
      commit("setMessagePreview", payload)
    },
    setCurrentDialogOpTime ({commit}, payload) {
      commit("setCurrentDialogOpTime", payload)
    },
    setCurrentDialogMerchantData ({commit}, payload) {
      commit("setCurrentDialogMerchantData", payload)
    },
    setSystemMessageSendStatus ({commit}, {key, value}) {
      commit("setSystemMessageSendStatus", {key, value})
    },
    // Other action states
    setShowHotIcon({ commit }, payload) {
      commit("setShowHotIcon", payload)
    },
    setBrandHistory({ commit }, payload) {
      commit("setBrandHistory", payload)
    },
    setProductAddToCartAfterLogin ({commit}, payload) {
      commit("setProductAddToCartAfterLogin", payload)
    },
    // Start action reseller states
    setResellerData({ commit }, payload) {
      commit("setResellerData", payload)
    },
    setResellerRegistration({ commit }, payload) {
      commit("setResellerRegistration", payload)
    },
    setMembershipPayment({ commit }, data) {
      commit("setMembershipPayment", data)
    },
    // End action reseller states
    // Additional action states
    setTopmenu({ commit }, payload) {
      commit("setTopmenu", payload)
    },
    setGroupedBrands({ commit }, payload) {
      commit("setGroupedBrands", payload)
    },

    // Action quick checkout states
    setGuestSession({ commit }, payload) {
      commit('setGuestSession', payload)
    },
    setGuestToken({ commit }, payload) {
      commit('setGuestToken', payload)
    },
    
    // Action isLoading states
    setIsLoading ({commit}, {key, value}) {
      commit("setIsLoading", {key, value})
    },

    //* Action isJuene state
    setIsJuene({ commit }, payload) {
      commit('setIsJuene', payload)
    },
  },
  getters: {
    getUserToken: (state) => state.user_token,
    getUserData: (state) => state.userData,
    getPaymentMethod: (state) => state.paymentMethod,
    getAgreeStatus: (state) => state.agreed,
    getAgreeLMStatus: (state) => state.agreedLM,
    getUseLizpoint: (state) => state.useLizpoint,
    getListPayment: (state) => state.listPayment,
    getShoppingBag: (state) => state.shoppingBag,
    getWishlist: (state) => state.wishlist,
    getOrderDetail: (state) => state.orderDetail,
    getCheckoutData: (state) => state.checkoutData,
    getBrandsList: (state) => state.brands,
    getCollectionsList: (state) => state.collections,
    getPromoList: (state) => state.promos,
    getCategoriesList: (state) => state.categories,
    getShowHotIcon: (state) => state.showHotIcon,
    getBrandHistory: (state) => state.brandHistory,
    getProductAddToCartAfterLogin: (state) => state.productAddToCartAfterLogin,
    // Start get reseller states
    getResellerData: (state) => state.resellerData,
    getResellerRegistration: (state) => state.resellerRegistration,
    getMembershipPayment: (state) => state.membershipPayment,
    // End get reseller states
    getIsLoggedIn: (state) => state.isLoggedIn,
    // get additional states
    getTopmenu: (state) => state.topmenu,
    getGroupedBrandsList: (state) => state.groupedBrands,

    // Get quick checkout states
    getGuestSession: (state) => state.tempSession,
    getGuestToken: (state) => state.tempToken,

    // Get QB states
    getQbSession: (state) => state.qbHasSession,
    getDialogList: (state) => state.dialogList,
    getCurrentDialog: (state) => state.currentDialog,
    getMessageList: (state) => state.messageList,
    getMessagePreview: (state) => state.messagePreview,
    getCurrentDialogOpTime: (state) => state.currentDialogOpTime,
    getCurrentDialogMerchantData: (state) => state.currentDialogMerchantData,
    getSystemMessageSendStatus: (state) => state.systemMessageSendStatus,

    // Get isLoading states
    getIsLoading: (state) => state.isLoading,

    // Get isLoading states
    getIsJuene: (state) => state.isJuene,
    
    // Get whizliz theme
    getWhizlizTheme: (state) => state.whizlizTheme,
  },
})
