<template>
  <div>
    <TopBanner />
    <WhyWhizliz :hasBanners="banner" />
    <BannerBox :banner="banner" v-if="banner"/>
    <CustomToast />
    <Countdown />
    <BestSeller />
    <!-- <ColabWhizliz /> -->
    <!-- <FindOurBPHomepage v-if="!isMembershipActive" /> -->
    <!-- <BPExclusiveProduct /> -->
    <!-- <hr> --> <!-- horizontal line for BP Exclusive Product, will be removed after new Revamp UI UX Whizliz -->
    <NewArrival />
    <ShopBrand />
    <LizMagazine />
    <!-- <FeaturedOn /> -->
    <HotIcon />
    <ModalPopupPromo />
    <b-modal
      id="download-app"
      modal-class="p-0 modal-bottomed"
      hide-header
      hide-footer
      no-stacking
    >
      <div class="modal-dialog" role="document" style="display: contents">
        <div class="modal-content" style="display: contents">
          <div class="modal-header p-0">
            <div class="modal-title d-flex align-items-center">
              <img
                src="Logomark-ICON-FAVICON.png"
                height="64"
                style="padding: 10px 15px 10px 5px"
                alt="Whizliz Icon"
              />
              <p class="m-0">
                Download our
                <a class="bold" :href="device.url">{{ device.type }} Apps</a>
              </p>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="$bvModal.hide('download-app')"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import { userService } from "@/_services";
// import Header from "@/components/mains/MainHeader.vue";
import TopBanner from "@/components/mains/TopBanner.vue";
import WhyWhizliz from "@/components/mains/WhyWhizliz.vue";
import BannerBox from "@/components/mains/BannerBox.vue";
import BestSeller from "@/components/mains/BestSeller.vue";
// import ColabWhizliz from "@/components/mains/CollabWhizliz.vue";
import NewArrival from "@/components/mains/NewArrival.vue";
import Countdown from "@/components/mains/Countdown";
import ShopBrand from "@/components/mains/ShopBrand.vue";
import LizMagazine from "@/components/mains/LizMagazine.vue";
// import FeaturedOn from "@/components/mains/FeaturedOn.vue";
import HotIcon from "@/components/mains/HotIcon.vue";
// import Footer from "@/components/mains/Footer.vue";
import ModalPopupPromo from "@/components/modals/ModalPopupPromo.vue";
const CustomToast = () => import('@/components/mains/CustomToast');
// const FindOurBPHomepage = () => import("@/components/mains/FindOurBPHomepage.vue");
// const BPExclusiveProduct = () => import("@/components/mains/BPExclusiveProduct.vue");

import moment from "moment";

export default {
  name: 'Homepage',
  components: {
    // Header,
    TopBanner,
    WhyWhizliz,
    BannerBox,
    BestSeller,
    // ColabWhizliz,
    NewArrival,
    Countdown,
    ShopBrand,
    LizMagazine,
    // FeaturedOn,
    HotIcon,
    // Footer,
    ModalPopupPromo,
    CustomToast,
    // FindOurBPHomepage,
    // BPExclusiveProduct
  },
  data() {
    return {
      device: {},
      banner: null,
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    resellerData() {
      return this.$store.getters.getResellerData;
    },
    isMembershipActive() {
      return (this.resellerData?.is_active && this.resellerData?.status_verify) || false;
    }
  },
  mounted() {
    // Check if route is for reseller account
    const isReseller = /reseller/.test(this.$route.path);
    if (this.$route.params.id) this.activateEmail(isReseller);

    if (this.$route.path == "/login") {
      if (this.userData) this.$nextTick(() => this.$router.replace("/"))
      else this.$bvModal.show("modalLogin") 
    }

    if (this.$route.path.indexOf("/register-bus-partner") > -1)
      this.$bvModal.show("modal-register-reseller");

    this.getMiniBanner('home')
    // this.checkDeviceType() Takeout download app pop up for mobile view
  },
  watch: {
    userData(newVal) {
      if (newVal && this.$route.path == "/login") {
        const next = this.$route.query.next || "/";
        this.$nextTick(() => this.$router.push(next));
      }
    },
  },
  methods: {
    async getMiniBanner(type) {
      try {
        const res = await this.$api.cms.getMiniBanner(type)
        if (res.status === 200) this.banner = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    checkDeviceType() {
      // Check mobile device type
      const android = /Android/.test(navigator.userAgent);
      const ios = /(iPhone|iPad|iPod)/.test(navigator.platform);
      if (android) this.device = {
        type: "Android",
        url: "https://play.google.com/store/apps/details?id=com.linowalden",
      };
      else if (ios) this.device = {
        type: "iOS",
        url: "https://apps.apple.com/us/app/id1513866635",
      };
      if ((android || ios) && document.cookie.indexOf("showDownloadApps=true") < 0) {
        setTimeout(() => {
          this.$bvModal.show("download-app");
        }, 3000);
        document.cookie =
          "showDownloadApps=true; expires=" +
          this.getExpirationDate() +
          "; path=/";
      }
    },
    async activateEmail(isReseller) {
      const data = {
        email: localStorage.getItem("email_login"),
        password: localStorage.getItem("password_login"),
      };
      const id = this.$route.params.id;
      try {
        const response = await userService.activateUser(id, isReseller);
        if (response.status === 200) {
          toaster.make("Account activated successfully", "success");
          if (data.email)
            setTimeout(() => {
              userService.loginWithEmail({
                email: data.email,
                password: data.password,
              });
              localStorage.removeItem("email_login");
              localStorage.removeItem("password_login");
            }, 3000);
          else this.$bvModal.show("modalLogin");
          this.$router.replace("/");
        }
      } catch (e) {
        if (e.response) toaster.make(e.response.data.message, "danger");
        console.error(e);
      }
    },
    getExpirationDate() {
      //UTC Format
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      return moment(String(tomorrow))
        .utc()
        .format("ddd, DD MMM YYYY HH:mm:ss UTC");
    },
  },
};
</script>
